import HSHeader from '../front-ui/vendor/hs-header/src/js/hs-header'
import HSMegaMenu from '../front-ui/vendor/hs-mega-menu/src/js/hs-mega-menu'
import HSUnfold from '../front-ui/vendor/hs-unfold/src/js/hs-unfold'
import '../front-ui/js/hs.core'
import '../front-ui/js/hs.validation'
import 'jquery-validation'
import HSShowAnimation from '../front-ui/vendor/hs-show-animation/src/js/hs-show-animation'
import HSCounter from '../front-ui/vendor/hs-counter/src/js/hs-counter'
import HSStickyBlock from '../front-ui/vendor/hs-sticky-block/src/js/hs-sticky-block'
import AOS from 'aos'
import HSGoTo from '../front-ui/vendor/hs-go-to/src/js/hs-go-to'
import HSVideoPlayer from '../front-ui/vendor/hs-video-player/src/js/hs-video-player'
import '../front-ui/vendor/slick-carousel/slick/slick'
import '../front-ui/js/hs.slick-carousel'
import Typed from '../front-ui/vendor/typed.js/lib/typed'
//Needs Implemenation
import '../front-ui/vendor/select2/dist/js/select2.full'
import '../front-ui/js/hs.select2'
import '../front-ui/vendor/simplePagination.js-master/jquery.simplePagination'
import '../front-ui/vendor/jquery-listnav-master/jquery-listnav.min'

$(document).on('ready', function () {


      $(".gloss-search-bar").submit(function(){
        event.preventDefault();
      });

    if (jQuery('#blog-page').length > 0) {

        var items = $(".bottom-row .row .col-lg-4");
        var numItems = items.length;

        if(jQuery('#blog-author-page').length > 0){
            var perPage = 6;
        }else{
            var perPage = 3;
        }
        

        items.slice(perPage).hide();

        $('#pagination-container').pagination({
            items: numItems,
            itemsOnPage: perPage,
            prevText: "Prev",
            nextText: "Next",
            onPageClick: function (pageNumber) {
                var showFrom = perPage * (pageNumber - 1);
                var showTo = showFrom + perPage;
                items.hide().slice(showFrom, showTo).show();
            }
        });

        $(".filter-tab #tags").on("change", function () {
            $.get('/blog/posts/' + $(this).val())
                .done(function (data) {
                    if (data.length > 0){
                        $('.bottom-row .row').empty();
                        $('.bottom-row .row').removeClass('ml-3');
                        $('#pagination-container').pagination('destroy');

                        $('.bottom-row .row').append(data).html();

                        var items = $(".bottom-row .row .col-lg-4");
                        var numItems = items.length;
                        var perPage = 3;

                        items.slice(perPage).hide();

                        var pagination_posts = $('#pagination-container').pagination({
                            items: numItems,
                            itemsOnPage: perPage,
                            prevText: "Prev",
                            nextText: "Next",
                            onPageClick: function (pageNumber) {
                                var showFrom = perPage * (pageNumber - 1);
                                var showTo = showFrom + perPage;
                                items.hide().slice(showFrom, showTo).show();
                            }
                        });
                    } else {
                        $('.bottom-row .row').empty();
                        $('.bottom-row .row').addClass('ml-3');
                        $('#pagination-container').pagination('destroy');
                        $('.bottom-row .row').append("No results found.").html();
                    }
                });
        });
    }

    if (jQuery('#blog-article-page').length > 0) {

        $.get('/blog/single/related/'+ post_id)
        .done(function( data ) {

          $('.row.related-posts-list').empty();
          $('#pagination-container').pagination('destroy');

          // data = data.replaceAll('&lt;!-- wp:paragraph --&gt;', "");
          // data = data.replaceAll('&lt;p&gt;', "");

          $('.row.related-posts-list').append(data).html().txt();

          var items = $(".row.related-posts-list .col-lg-4");
          var numItems = items.length;
          var perPage = 3;

          items.slice(perPage).hide();

          

          var pagination_posts = $('#pagination-container').pagination({
              items: numItems,
              itemsOnPage: perPage,
              prevText: "Prev",
              nextText: "Next",
              onPageClick: function (pageNumber) {
                  var showFrom = perPage * (pageNumber - 1);
                  var showTo = showFrom + perPage;
                  items.hide().slice(showFrom, showTo).show();
              }
          });
        });
    } 


    //ebook

    if (jQuery('#ebook-page').length > 0) {
        var perPage = 6; // Set the number of items per page
    
        var items = $(".bottom-row .row .col-lg-4");
        var numItems = items.length;
    
        items.slice(perPage).hide();
    
        $('#pagination-ebook').pagination({
            items: numItems,
            itemsOnPage: perPage,
            prevText: "<span class='fa fa-arrow-left'></span>",
            nextText: "<span class='fa fa-arrow-right'></span>",
            onPageClick: function (pageNumber) {
                var showFrom = perPage * (pageNumber - 1);
                var showTo = showFrom + perPage;
                items.hide().slice(showFrom, showTo).show();
            }
        });
    
    
    }
    
    // initialization of header
    // var header = new HSHeader($('#header')).init();

    // initialization of mega menu
    var megaMenu = new HSMegaMenu($('.js-mega-menu'), {
        desktop: {
          position: 'left'
        }
      }).init();

    // initialization of unfold
    var unfold = new HSUnfold('.js-hs-unfold-invoker').init();

    // initialization of text animation (typing)
    if($(".js-text-animation")[0]){
        var typed = new Typed(".js-text-animation", {
            strings: ["Sales.", "Service.", "Marketing."],
            typeSpeed: 140,
            loop: true,
            backSpeed: 65,
            backDelay: 1600
        });
    }
    
    // initialization of form validation
    $('.js-validate').each(function() {
       
        var validation = $.HSCore.components.HSValidation.init($(this), {
            rules: {
                inf_field_Phone1: {
                    required: true,
                    digits: true,
                    rangelength: [10, 10],
                },
            }
        });            
    });

    // initialization of select picker ( Needs Impelmentation, Will not accept other classes, or work with j-query validate)
    $('.js-custom-select').each(function () {
        var select2 = $.HSCore.components.HSSelect2.init($(this));
    });
    
    // initialization of slick carousel
    $('.js-slick-carousel').each(function() {
        var slickCarousel = $.HSCore.components.HSSlickCarousel.init($(this));
    });
    
    // initialization of video player
    $('.js-inline-video-player').each(function () {
        var videoPlayer = new HSVideoPlayer($(this)).init();
    });

    // initialization of show animations
    $('.js-animation-link').each(function () {
    var showAnimation = new HSShowAnimation($(this)).init();
    });

    // initialization of counter
    $('.js-counter').each(function() {
    var counter = new HSCounter($(this)).init();
    });

    //Side Bar
    $('.js-sticky-block').each(function () {
        var stickyBlock = new HSStickyBlock($(this)).init();
    });

    // initialization of go to
    $('.js-go-to').each(function () {
    var goTo = new HSGoTo($(this)).init();
    });

    // Scroll to (Section)
    $(function () {
        $('a[href*=#scroll-to-]:not([href=#scroll-to-])').on('click', function () {
            if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top - 10
                    }, 1000);
                    return false;
                }
            }
        });
    });

    // initialization of aos for sidebar tabs
    AOS.init({
        duration: 550,
        once: true
    });
    
    $('#demoOne').listnav({
        includeNums: false,
        showCounts: false,
        filterSelector: '.terminology-name',
        noMatchText: '',
        onClick: function(letter) {
            if (letter.toUpperCase() == 'ALL') {
                window.location.href = "/glossary";
            }
        }
    });

    $('.terminology-search').keyup(function(){

         let text = this.value.toLowerCase(); 

        $("#demoOne .terminology-container").each(function( index, element ) {
          

              var term_text = $(element).find('.terminology-name').text().toLowerCase();
              
              if (term_text.search(text) > -1) {
                  $(element).removeClass('listNavHide');
                  $(element).addClass('listNavShow');
              }else{
                  $(element).removeClass('listNavShow');
                  $(element).addClass('listNavHide');
              }

        });
         
         
    });

     function waitForElement(elementPath, callBack){
        window.setTimeout(function(){
          if($(elementPath).length){
            callBack(elementPath, $(elementPath));
          }else{
            waitForElement(elementPath, callBack);
          }
        },500)
      }

      
    $('#chatUsModal').on('show.bs.modal', function (e) {
        $('body').addClass("activeShowChatUsModal");
    });

    $('#chatUsModal').on('hidden.bs.modal', function (e) {
        $('body').removeClass("activeShowChatUsModal");
    });

    $('.ChatUsModalLiveButtons').on('click', function(){
        $('#chatUsModal').modal('hide');
    });

});

function isExcludedPage() {
    var excludedPages = [
        '/blog' // Add more paths here if needed
    ];
    return excludedPages.includes(window.location.pathname);
}

if (!isExcludedPage()) {

document.addEventListener('scroll', function() {
    var scrollDepth = window.scrollY / (document.documentElement.scrollHeight - window.innerHeight);
    if (scrollDepth > 0.4) {
        var requestQuoteButton = document.getElementById('requestQuoteButton');
        if (!requestQuoteButton.classList.contains('clicked')) {
            requestQuoteButton.classList.add('clicked');
            requestQuoteButton.click();
        }
    }
});

document.addEventListener('DOMContentLoaded', function() {
    var unfoldInvokers = document.querySelectorAll('.js-hs-unfold-invoker');
    unfoldInvokers.forEach(function(invoker) {
        invoker.addEventListener('click', function() {
            var target = document.querySelector(this.getAttribute('data-hs-unfold-options').target);
            if (target.classList.contains('is-visible')) {
                target.classList.remove('is-visible');
                target.style.animation = 'slideOutLeft 0.5s forwards';
            } else {
                target.classList.add('is-visible');
                target.style.animation = 'slideInLeft 0.5s forwards';
            }
        });
    });
});


$('#academy-create-user-form').on('submit', function (event) {
    event.preventDefault();


    var formData = {
        email: $('#email_address').val(),
        password: $('#password').val(),
        first_name: $('input[name="first_name"]').val(),
        last_name: $('input[name="last_name"]').val()
    };

    
    $.post("https://wordpress.academy.prod.getdyl.com/wp-json/sections/v1/academy/register/",
        formData,
        function (data, status) {
            try {
                var new_user_data = JSON.parse(data);
                if (new_user_data.message) {
                    $('.account-info-alert-container').html('<div class="alert alert-success" role="alert">' + new_user_data.message + '</div>');

                  
                    setTimeout(function () {
                        window.location.href = 'https://academy.dyl.com/dyl-academy/login';
                    }, 1000);
                }
            } catch (e) {
                console.error("Error parsing response:", e);
                $('.account-info-alert-container').html('<div class="alert alert-danger" role="alert">An error occurred. Please try again.</div>');
            }
        }).fail(function (xhr, status, error) {
            console.error("AJAX request failed:", status, error);
            $('.account-info-alert-container').html('<div class="alert alert-danger" role="alert">An error occurred. Please try again.</div>');
        });

    return false; // Prevent default form submission
});

document.getElementById('next1').addEventListener('click', function () {
    document.getElementById('step1').classList.remove('active');
    document.getElementById('step2').classList.add('active');
});

document.getElementById('prev').addEventListener('click', function () {
    document.getElementById('step2').classList.remove('active');
    document.getElementById('step1').classList.add('active');
});

}